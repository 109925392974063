import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"
import ImageDownloadSocial from "../../../components/ImageDownloadSocial"



const IndexPage = () => {
    const root = "/img/download/coffee-coworking"

    return (
        <Layout pageTitle="Coffee & Coworking - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/events/coffee-coworking">Back</Link>
                </Col>
            </Row>

           <Row className="mt-5">
                <Col>
            <h1>General Promo</h1>
            </Col>
    </Row>

            <ImageDownloadSocial
            root ="/img/download/coffee-coworking"
            filename="coffee-coworking-social"
            name="Social Media Banners"
            text="Use these as your social media cover photos. "
            text2=""
            linkedinCompany="https://www.canva.com/design/DAFThDbtDD4/yu9Jfv_N2xU--1E_TYKhaQ/view?utm_content=DAFThDbtDD4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            linkedinProfile="https://www.canva.com/design/DAFW5FiT4hE/qFI1Jlr1yEWEwqEh520rUQ/view?utm_content=DAFW5FiT4hE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            fbgroup="https://www.canva.com/design/DAFQtGL-ww8/SuoDPTUHlyNMmRp33uQYoA/view?utm_content=DAFQtGL-ww8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            fbpage="https://www.canva.com/design/DAFThLvi5EQ/hmputGPJYiwNkyUWlYd1dw/view?utm_content=DAFThLvi5EQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            fbprofile="https://www.canva.com/design/DAFW5EIXQq0/pA4rID4NDbwc9HacMtwyDA/view?utm_content=DAFW5EIXQq0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            twitter="https://www.canva.com/design/DAFThB3qHEc/BxCJdPIoW-iFzXPJswcFRA/view?utm_content=DAFThB3qHEc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1ps3-EnwEbA7S1MKmSz2WpCuu6vjisCYVjZUjpWgI4wM/edit?usp=sharing"
            />
                
            <ImageDownload
            root ="/img/download/coffee-coworking"
            filename="coffee-coworking-general"
            name="Coffee &amp; Coworking General Promo 1"
            text="Use these to promote Coffee & Coworking on your social media"
            text2=""
            squareHref="https://www.canva.com/design/DAFohVEIbgU/eRm0DNikXuoRDD9eXE7Ryg/view?utm_content=DAFohVEIbgU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFU1fXq1Q8/gCGzWOqTDB-tGm8BlrQt3g/view?utm_content=DAFU1fXq1Q8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1ps3-EnwEbA7S1MKmSz2WpCuu6vjisCYVjZUjpWgI4wM/edit?usp=sharing"
            />  
            
            <ImageDownload
            root ="/img/download/coffee-coworking"
            filename="coffee-coworking-winter"
            name="Coffee &amp; Coworking General Promo 2"
            text="Use these to promote Coffee & Coworking on your social media"
            text2=""
            squareHref="https://www.canva.com/design/DAFUpnqFxQ0/xXx5z3hI5CapY7OnuJgc3A/view?utm_content=DAFUpnqFxQ0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFUpmrS2Ok/UEoevXcDhxhZDhJ8G5Ai9A/view?utm_content=DAFUpmrS2Ok&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1ps3-EnwEbA7S1MKmSz2WpCuu6vjisCYVjZUjpWgI4wM/edit?usp=sharing"
            />

<Row className="mt-5">
                <Col>
            <h1>New Event Promo</h1>
            </Col>
    </Row>

<ImageDownload
            root ="/img/download/coffee-coworking"
            filename="new-event"
            name="Coffee &amp; Coworking New Event Announcement"
            text="Use these to announce a new event coming up!"
            text2=""
            squareHref="https://www.canva.com/design/DAFpKVNCPpo/zYgG8hJ5p2IvLA2QAkW3aQ/view?utm_content=DAFpKVNCPpo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFpKcBn_Lk/9Om87SHsDxK-fvBPDukDNQ/view?utm_content=DAFpKcBn_Lk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1ps3-EnwEbA7S1MKmSz2WpCuu6vjisCYVjZUjpWgI4wM/edit?usp=sharing"
            />

            
        </Layout>

        
    )
}

export default IndexPage
